import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/layout";

import { Badge, Card, Row, Col, Button } from "react-bootstrap";

import styles from "./story.module.css";

class StoryTemplate extends React.Component {
  render() {
    const story = get(this.props, "data.contentfulStory");

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={`${story.title} | ${story.countryOfOrigin.name}`} />
          <div className="wrapper">
            <Row>
              <Col sm={8}>
                <h1 className={styles.sectionHeadline}>{story.title}</h1>
                <p
                  style={{
                    display: "block"
                  }}
                  className="mb-2 text-muted"
                >
                  <Img alt="" fixed={story.countryOfOrigin.flag.fixed} /> Posted on {story.publishDate}
                  <span className="float-right d-none d-md-block">
                    <strong>{story.location}</strong>
                  </span>
                </p>
                <p
                  style={{
                    display: "block"
                  }}
                  className="d-sm-none"
                >
                  <strong>{story.location}</strong>
                </p>
                {story.tags.map((tag, i) => {
                  return (
                    <Badge key={i} variant="light" className="mr-1">
                      {tag}
                    </Badge>
                  );
                })}
                <div
                  dangerouslySetInnerHTML={{
                    __html: story.content.childMarkdownRemark.html
                  }}
                />
              </Col>

              <Col sm={4}>
                <Card className={styles.countryCard}>
                  <div className={styles.countryLogo}>
                    <Img alt="" fluid={story.countryOfOrigin.flag.fluid} />
                  </div>
                  <Card.Body>
                    <Card.Title className={styles.countryTitle}>
                      {story.countryOfOrigin.name}
                    </Card.Title>
                    <Card.Text>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            story.countryOfOrigin.shortBio.childMarkdownRemark
                              .html
                        }}
                      />
                    </Card.Text>
                    <Card.Text className={styles.credit}>
                    Credit -> <a href={story.credit.url}>{story.credit.name}</a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default StoryTemplate;

export const pageQuery = graphql`
  query StoryById($id: String!) {
    contentfulStory(id: { eq: $id }) {
      id
      title
      tags
      publishDate(formatString: "MMMM Do, YYYY")
      shortBio {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      countryOfOrigin {
        name
        shortBio {
          childMarkdownRemark {
            html
          }
        }
        flag {
          fluid(maxWidth: 100, background: "rgb:ffffff") {
            ...GatsbyContentfulFluid
          }
          fixed(width: 20, background: "rgb:ffffff") {
            ...GatsbyContentfulFixed
          }
        }
      }
      credit {
        name
        url
      }
    }
  }
`;
